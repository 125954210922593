<template>
<div class="p-grid">
    <div class="home">
        <div class="features" style="text-align: center;background-image: url('./images/prefondo4.png');">
            <h3 style="color:#ffcc2a;">{{$t('Cotización de Pólizas')}}</h3>
            <div class="p-grid" >

                <div class="p-col-12 p-md-12">
                    <div class="feature-card">
                        <DataTable :value="poliza_incendio" stripedRows responsiveLayout="scroll">
                            <template #header>
                                {{$t('Fire Insurance')}}
                            </template>
                            <Column :header="$t('Estatus')" headerStyle="width: 200px">
                                <template #body="slotProps">
                                    <div v-if="i18n.locale() == 'es'">{{slotProps.data.evaluacion.tipopoliza_nombre}}</div>
                                    <div v-else>{{slotProps.data.evaluacion.tipopoliza_name}}</div>
									<Avatar icon="bi bi-shield-shaded" class="p-mr-2" size="large" style="background-color:gray; color: #ffffff" v-if="slotProps.data.estado==7"/>
									<div v-if="slotProps.data.estado==7">
										{{$t('Proceso Renovación')}}
									</div>
                                    <Knob v-model="slotProps.data.estado" :min="0" :max="3" :valueTemplate="(parseFloat(slotProps.data.estado)*100/3).toFixed(0)+'%'"  readonly="false" v-if="slotProps.data.estado<=3"/>
                                    {{slotProps.data.fecha}}                              
                                </template>
                            </Column> 
                            <Column field="id" :header="$t('Nº')" headerStyle="width: 70px">
                                <template #body="slotProps">
                                    {{slotProps.data.id}}                              
                                </template>
                            </Column>
                            <Column :header="$t('Detalles')">
                                <template #body="slotProps">
                                    <Button style="width: 120px;" type="button" :label="$t('Documento')" @click="VerIMG(slotProps.data)" class="p-button-success" icon="pi pi-eye" iconPos="right"/>
                                    <div v-if="slotProps.data.evaluacion.comentario && slotProps.data.evaluacion.comentario != ''">
                                        <br><Button style="width: 120px;" type="button" :label="$t('Observación')" @click="VerMENS(slotProps.data.evaluacion.comentario)" class="p-button-info" icon="pi pi-eye" iconPos="right"/>
                                    </div>
                                </template>
                            </Column>
                            <Column :header="$t('Nombre Completo')" field="nombre">
                                <template #body="slotProps">
									<div>
										{{slotProps.data.nombre}}
									</div>
                                </template>
                            </Column>
                            <Column field="cuotas" :header="$t('Monto Cuota')">
                                <template #body="slotProps">
									{{moneda(((slotProps.data.evaluacion.primabrutat*duracion[slotProps.data.duracion].valor*(1+cuotas[slotProps.data.cuotas].porcentaje/100)+parseFloat(slotProps.data.evaluacion.kosten))*(1+parseFloat(slotProps.data.evaluacion.impuesto)/100))/cuotas[slotProps.data.cuotas].valor)}}
                                </template>
                            </Column>
                            <Column :header="$t('Monto Total')">
                                <template #body="slotProps">
                                    {{moneda((slotProps.data.evaluacion.primabrutat*duracion[slotProps.data.duracion].valor*(1+cuotas[slotProps.data.cuotas].porcentaje/100)+parseFloat(slotProps.data.evaluacion.kosten))*(1+parseFloat(slotProps.data.evaluacion.impuesto)/100))}}
                                    <br><br>
                                    <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(slotProps.data)"/>
                                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="EditPoliza(slotProps.data)" v-if="edit"/>
                                </template>
                            </Column>					
                        </DataTable>
                    </div>
                </div>

          </div>
        </div>
	</div>

	<Dialog v-model:visible="Dialog" :style="{width: size}" :header="$t('Fire Insurance')" :modal="true" :maximizable="true">
		<br>
        <div class="confirmation-content">
            <div class="p-field">
                <h4>{{$t('Información Básica')}}</h4>
				<div class="p-grid">
					<div class="p-col-3">
						{{$t('Fecha')}}: <strong>{{poliza.fecha}}</strong>			
					</div>
					<div class="p-col-3">
						{{$t('Número ID')}}: <strong>{{poliza.code}}</strong>			
					</div>
					<div class="p-col-3" v-if="i18n.locale() == 'es' && poliza.tipocliente==1">
						{{$t('Sexo')}}: <strong>{{poliza.sexnombre}}</strong> 			
					</div>
					<div class="p-col-3" v-if="i18n.locale() == 'en' && poliza.tipocliente==1">
						{{$t('Sexo')}}: <strong>{{poliza.sexname}}</strong> 			
					</div>
					<div class="p-col-6">
						{{$t('Nombre Completo')}}: <strong>{{poliza.nombre}}</strong>			
					</div>
					<div class="p-col-6">
						{{$t('Correo')}}: <strong>{{poliza.correo}}</strong>			
					</div>
					<div class="p-col-4" v-if="poliza.tipocliente==1">
						{{$t('Fecha de nacimiento')}}: <strong>{{poliza.fechanac}}</strong>			
					</div>
					<div class="p-col-2" v-if="poliza.tipocliente==1">
						{{$t('Edad')}}: <strong>{{poliza.edad}}</strong>			
					</div>
					<div class="p-col-6" v-if="i18n.locale() == 'es'">
						{{$t('Nacionalidad')}}: <strong>{{poliza.nacionalidad}}</strong> 			
					</div>
					<div class="p-col-6" v-else>
						{{$t('Nacionalidad')}}: <strong>{{poliza.nationality}}</strong> 			
					</div>
					<div class="p-col-4">
						{{$t('Localidad')}}: <strong>{{poliza.localidadn}}</strong>			
					</div>
					<div class="p-col-4">
						{{$t('Teléfono móvil')}}: <strong>{{poliza.celular}}</strong>			
					</div>
					<div class="p-col-4">
						{{$t('Teléfono privado')}}: <strong>{{poliza.tlflocal}}</strong>			
					</div>
				</div>
            </div>
            <br>
            <div class="p-field">
                <h4>{{$t('Documentos Personales')}}</h4>
				<div class="p-grid">
					<div class="p-col-4">
						{{$t('Número ID')}}:<strong>{{poliza.code}}</strong>
					</div>
					<div class="p-col-4">
						<img style="width: 100px;" :src="url.upload+poliza.documento" @click="toggle" />
						<OverlayPanel ref="op" appendTo="body" :showCloseIcon="true">
							<img style="width: 350px;" :src="url.upload+poliza.documento"/>
						</OverlayPanel>
					</div>
				</div>
            </div>
            <br>
            <div class="p-field">
                <h4>{{$t('Datos de la Propiedad')}}</h4>
				<div class="p-grid">
					<div class="p-col-12">
						{{$t('Dirección')}}:<strong>{{poliza.direccion}}</strong>
					</div>
					<div class="p-col-12">
						{{$t('Comentario')}}:<strong>{{poliza.comentario}}</strong>
					</div>
					<div class="p-col-6" v-if="poliza.tipocliente==1">
						{{$t('Evaluación de la propiedad (mínimo 6 meses)')}}:<strong>{{poliza.comentario}}</strong>
					</div>
					<div class="p-col-6" v-if="poliza.tipocliente==2">
						{{$t('Estados financieros - Últimos tres años')}}:<strong>{{poliza.comentario}}</strong>
					</div>
					<div class="p-col-6">
						<img style="width: 50px;" src="images/pdf.jpg" @click="VerPDF(poliza.pdf_eval)"/>
					</div>
					<div class="p-col-6" v-if="poliza.tipocliente==1">
						{{$t('Titulo de propiedad')}}:<strong>{{poliza.comentario}}</strong>
					</div>
					<div class="p-col-6" v-if="poliza.tipocliente==2">
						{{$t('Informe Cope')}}:<strong>{{poliza.comentario}}</strong>
					</div>
					<div class="p-col-6">
						<img style="width: 50px;" src="images/pdf.jpg" @click="VerPDF(poliza.pdf_tit)"/>
					</div>
				</div>
            </div>
            <br>
            <div class="p-field">
                <h4>{{$t('Cobertura')}}</h4>
				<DataTable :value="poliza.descrip1" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
						:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<Column :header="$t('Descripción')">
						<template #body="slotProps">
							<div v-if="i18n.locale() == 'es'">{{slotProps.data.nombre}}</div>
							<div v-if="i18n.locale() == 'en'">{{slotProps.data.name}}</div>
						</template>
					</Column>
					<Column :header="$t('Valor')">
						<template #body="slotProps">
							<InputText v-model="slotProps.data.valor" type="number" @change="Calculo()"/>
						</template>
					</Column>
					<Column :header="$t('Rate')+' %'">
						<template #body="slotProps">
							<InputText v-model="slotProps.data.evaluo" type="number" @change="Calculo()"/>
						</template>
					</Column>
				</DataTable>
            </div>			
            <br>
            <div class="p-field"  v-if="poliza.evaluacion.tipopoliza==5">
                <h4>{{$t('Inventario')}}</h4>
				<DataTable :value="poliza.inventario" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
						:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<Column :header="$t('Descripción')">
						<template #body="slotProps">
							{{slotProps.data.opcion}}
						</template>
					</Column>
					<Column :header="$t('Valor')">
						<template #body="slotProps">
							<InputText v-model="slotProps.data.valor" type="number" @change="Calculo()"/>
						</template>
					</Column>
					<Column :header="$t('Rate')+' %'">
						<template #body="slotProps">
							<InputText v-model="slotProps.data.evaluo" type="number" @change="Calculo()"/>
						</template>
					</Column>
				</DataTable>
            </div>			
            <br>
            <div class="p-field" v-if="poliza.tipocliente==2">
                <h4>{{$t('Continuidad de Negocio')}}</h4>
				<DataTable :value="poliza.descrip2" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
						:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<Column :header="$t('Descripción')">
						<template #body="slotProps">
							<div v-if="i18n.locale() == 'es'">{{slotProps.data.nombre}}</div>
							<div v-if="i18n.locale() == 'en'">{{slotProps.data.name}}</div>
						</template>
					</Column>
					<Column :header="$t('Valor')">
						<template #body="slotProps">
							<InputText v-model="slotProps.data.valor" type="number" @change="Calculo()"/>
						</template>
					</Column>
					<Column :header="$t('Índice')">
						<template #body="slotProps">
							<InputText v-model="slotProps.data.evaluo" type="number" @change="Calculo()"/>
						</template>
					</Column>
				</DataTable>
            </div>			
            <br>
            <div class="p-field">
                <h4>{{$t('Evaluación')}}</h4>
                <h5 v-if="i18n.locale() == 'es'">{{$t('Tipo de Póliza')}}: <strong>{{poliza.evaluacion.tipopoliza_name}}</strong></h5>
                <h5 v-else>{{$t('Tipo de Póliza')}}: <strong>{{poliza.evaluacion.tipopoliza_name}}</strong></h5>     
				<div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-12">
                        <Checkbox v-model="poliza.exoneracion" :binary="true"/> <strong>{{$t('Exoneracion')}}</strong><br><br>
                    </div>
					<div class="p-col-3">
						<label>{{$t('Costo')}}</label>
						<InputText v-model="this.poliza.evaluacion.kosten" type="number" min="0"/>
					</div>
					<div class="p-col-3">
						<label for="state">{{$t('Descuento Especial')}}</label>
						<Dropdown v-model="poliza.evaluacion.descuento" :options="descuentos" optionLabel="nombre" placeholder="Select..." @change="Calculo()"></Dropdown>
					</div>	
					<div class="p-col-3">
						<label for="state">{{$t('Duración')}}</label>
						<Dropdown v-model="poliza.duracion" :options="duracion" :optionLabel="$t('nombre')" placeholder="Select..." @change="Calculo()"></Dropdown>
					</div>	
					<div class="p-col-3" v-if="poliza.duracion">
						<label for="state">{{$t('Pagos')}}</label>
						<Dropdown v-model="poliza.cuotas" :options="cuotas" :optionLabel="$t('nombre')" placeholder="Select..." @change="Calculo()" v-if="poliza.duracion.code==0"></Dropdown>
						<Dropdown v-model="poliza.cuotas" :options="cuotas2" :optionLabel="$t('nombre')" placeholder="Select..." @change="Calculo()" v-if="poliza.duracion.code==1"></Dropdown>
						<Dropdown v-model="poliza.cuotas" :options="cuotas1" :optionLabel="$t('nombre')" placeholder="Select..." @change="Calculo()" v-if="poliza.duracion.code==2"></Dropdown>
					</div>	
					<div class="p-col-12">
						<label>{{$t('Observación')}}</label>
						<Textarea v-model="poliza.evaluacion.comentario" rows="2"/>
					</div>
				</div>
            </div>
            <div class="p-field">
				<h4>{{$t('Cálculo Anual')}}</h4>
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-col-6"></div>
					<div class="p-col-3"><strong>{{$t('Bruta')}}</strong></div>
					<div class="p-col-3"><strong>{{moneda(premie)}}</strong></div>
					<div class="p-col-6"></div>
					<div class="p-col-3">{{$t('Descuento Especial')}}</div>
					<div class="p-col-3">{{moneda(premie*poliza.evaluacion.descuento.valor)}}</div>
				</div>
            </div>
            <div class="p-field">			
                <h4>{{$t('Resumen de Póliza')}}</h4>
                <div class="p-grid">
					<div class="p-col-3">
						{{$t('Duración')}}: 
						<strong v-if="i18n.locale() == 'es'">{{poliza.duracion.nombre}}</strong>
						<strong v-else>{{poliza.duracion.name}}</strong> 
					</div>
					<div class="p-col-3">
						{{$t('Cuotas')}}: <strong>{{poliza.cuotas.valor}}</strong> 
					</div>
					<div class="p-col-3"><strong>{{$t('Bruta')}}</strong></div>
					<div class="p-col-3"><strong>{{moneda((parseFloat(premie*(1-poliza.evaluacion.descuento.valor)))*(1+poliza.cuotas.porcentaje/100)*poliza.duracion.valor)}}</strong></div>
					<div class="p-col-6"></div>
					<div class="p-col-3"><strong>{{$t('Costo')}}</strong></div>
					<div class="p-col-3"><strong>{{moneda(poliza.evaluacion.kosten)}}</strong></div>
					<div class="p-col-6"></div>
					<div class="p-col-3"><strong>{{$t('OB')}}</strong></div>
					<div class="p-col-3"><strong>{{moneda(ob)}}</strong></div>
					<div class="p-col-6"></div>
					<div class="p-col-3"><h4><strong>{{$t('Total Premium')}}</strong></h4></div>
					<div class="p-col-3"><h4><strong>{{moneda(((parseFloat(premie*(1-poliza.evaluacion.descuento.valor))*(1+poliza.cuotas.porcentaje/100)*poliza.duracion.valor)+parseFloat(poliza.evaluacion.kosten)+parseFloat(ob)).toFixed(2))}}</strong></h4></div>
				</div>
            </div>
		</div>
		<template #footer>
			{{$t('Estatus')}}: 
			<Dropdown v-model="poliza.estado" :options="estados" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" v-if="poliza.estado!=7" ></Dropdown>
			<InputSwitch v-model="procesar" v-if="poliza.estado==7"/>
			<Button :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary" @click="save" />
			<Button :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="Dialog = false"/>
		</template>
	</Dialog>

	<Dialog v-model:visible="DiagIMG" header=" " :style="{width: size}" :modal="true">
        <DataTable :value="[ver_mostrar]" stripedRows responsiveLayout="scroll">
            <Column :header="$t('Número ID')">
				<template #body="slotProps">
					{{slotProps.data.code}}
				</template>
			</Column>
            <Column :header="$t('Nombre Completo')">
                <template #body="slotProps">
                    {{slotProps.data.nombre}}
                    <Avatar icon="bi bi-asterisk" class="p-mr-2" style="background-color:blue; color: #ffffff" v-if="slotProps.data.habitual"/>
                </template>
            </Column>
            <Column :header="$t('Documento')">
                <template #body="slotProps">
                    <img style="width: 100px;" :src="url.upload+slotProps.data.documento"/>
                </template>
            </Column>
        </DataTable>
	</Dialog>
	<Dialog v-model:visible="DiagMENS" header=" " :style="{width: size}" :modal="true">
        <br>
        {{mensaje}}
	</Dialog>

</div>
</template>

<script>
import API from "../service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import PDF from "../service/PDF";

export default {
	components: {
    },
	data() {
		return {
			insert: false,
			edit: false,
			del: false,
			i18n: null,
            caso: null,
            Dialog: false,
            poliza_incendio: [],
			DiagIMG: false,
			imagen: null,
			poliza: null,
			size: '60vw',
			size2: '25vw',
			url: null,
			estados: [],
			noclaims: [],
			descuentos: [],
            duracion: [],
            cuotas: [],
            cuotas1: [],
            cuotas2: [],
            costos: [],
            costo: null,
            poliza_valores: [],
            factor_limite: 0,
            recarga_edadveh: 0,
            recarga_edad: 0,
            recarga_volante: 0,
			premie: 0,
			kosten: 0,
			crs: 0,
			ob: 0,
			impuesto: 0,
			ver_mostrar: null,
			Caso: 0,
			DiagMENS: false,
			mensaje: null,
			procesar: false,
			renovacion: false

		}
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';this.size2 = '90vw';}
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
        Mostrar(){
			this.$store.commit('Loading');
			this.estados = [];
			this.poliza = null;
			this.poliza_incendio = [];
            this.Dialogo = false;
			this.duracion = [];
			this.cuotas = [];
			this.cuotas1 = [];
            this.cuotas2 = [];
            const Consulta = new API('Evaluacion');
            Consulta.Ini('Incendio').then(result => {
                //this.$store.state.error = result;
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
				if(result.consult.poliza_incendio){
					this.poliza_incendio = result.consult.poliza_incendio;
				}				
				this.estados = result.consult.estados;
				this.descuentos = result.consult.descuentos;
                this.duracion = result.consult.duracion;
                this.cuotas = result.consult.cuotas;
				this.cuotas.forEach(element => {
					if(element.code==0){
						this.cuotas1.push(element);
						this.cuotas2.push(element);
					}
					if(element.code==1){
						this.cuotas2.push(element);
					}
				});
                //this.costos = result.consult.costos;
                this.kosten = result.consult.valores.costo1;
				this.impuesto = result.consult.valores.impuesto;
                this.$store.commit('Loading');
            }); 
        },
        VerPDF(document){
            window.open(url.upload+document+'.pdf', '_blank');
		},
		VerIMG(data){
			this.ver_mostrar = data;
			this.DiagIMG = true;
		},
		VerMENS(data){
			this.mensaje = data;
			this.DiagMENS = true;
		},
		EditPoliza(poliza) {
			this.$store.commit('Loading');
			this.poliza = null;
			this.poliza = {...poliza};
            this.descuentos.forEach(element => {
                if(this.poliza.evaluacion.descuento==element.code){
					this.poliza.evaluacion.descuento = element;
                }
            });
			this.estados.forEach(element => {
                if(this.poliza.estado==element.code){
                    this.poliza.estado = element;
                }
            });  
            if(poliza.estado==7){
				this.renovacion = true;
            } else {this.renovacion = false;}
			this.duracion.forEach(element => {
                if(this.poliza.duracion==element.code){
                    this.poliza.duracion = element;
                }
            });  
			this.cuotas.forEach(element => {
                if(this.poliza.cuotas==element.code){
                    this.poliza.cuotas = element;
                }
            });
			this.Calculo();
            this.Dialog = true;
			this.$store.commit('Loading');
		},
		Calculo(){
			this.premie = 0;
            this.poliza.descrip1.forEach(element => {
				this.premie = parseFloat(this.premie) + parseFloat(element.valor)*(parseFloat(element.evaluo/100));
            });
            this.poliza.descrip2.forEach(element => {
				this.premie = parseFloat(this.premie) + parseFloat(element.evaluo);
            });
            this.poliza.inventario.forEach(element => {
				this.premie = parseFloat(this.premie) + parseFloat(element.valor)*(parseFloat(element.evaluo/100));
            });
			this.ob = (parseFloat(this.premie*(1-this.poliza.evaluacion.descuento.valor)*(1+this.poliza.cuotas.porcentaje/100)*this.poliza.duracion.valor)+parseFloat(this.poliza.evaluacion.kosten))*this.impuesto/100;		
		},
		Ver(data){
            const doc = new PDF();
			var monto = ((data.evaluacion.primabrutat*this.duracion[data.duracion].valor*(1+this.cuotas[data.cuotas].porcentaje/100)+parseFloat(data.evaluacion.kosten))*(1+parseFloat(data.evaluacion.impuesto)/100));
            doc.Cotizacion(1,data,this.duracion,this.cuotas,monto);
        },
		toggle(event) {
			this.$refs.op.toggle(event);
		},
		toggle2(event) {
			this.$refs.op2.toggle(event);
		},
		save() {
			//if (this.poliza.evaluacion.tipopoliza == null) {
			//	this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Tipo de Póliza'), life: 10000});
			//} else if (this.poliza.evaluacion.valorcatalogo == 0) {
			//	this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Valor Catálogo'), life: 10000});
			//} else {		
				//this.$store.commit('Loading');	
				const Consulta = new API('Evaluacion');          
				Consulta.Procesar('Incendio',{
					caso: 	'UPD',
					id: this.poliza.id,
					estado: this.poliza.estado.code,
					primabruta: this.premie,
					comentario: this.poliza.evaluacion.comentario,
					kosten: this.poliza.evaluacion.kosten,
					ob: this.ob,
					descuento: this.poliza.evaluacion.descuento.code,
					descrip1: this.poliza.descrip1,
					descrip2: this.poliza.descrip2,
					inventario: this.poliza.inventario,
					duracion: this.poliza.duracion,
					cuotas: this.poliza.cuotas,
					procesar: this.procesar,
					renovacion: this.renovacion,
					usuario: this.poliza.usuario,
					exoneracion: this.poliza.exoneracion,
					leng: this.i18n.locale(),
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
						//this.$store.commit('Loading');
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('No fue procesada la solicitud'), 
							life: 3000
						});
						//this.$store.commit('Loading');
					}
					this.Dialog = false;
					this.poliza = null;
					
				});
			//}
		},
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},


	},
	computed: {

    }

}
</script>
